export function calculateAverageScores(data) {
  // Create an object to store the scores for each period
  const periodScores = {};

  // Iterate over the data array
  data?.forEach((item) => {
    const { question, score, period } = item;
    if (question === 2 || question === 3 || question === 7) {
      // Check if the period already exists in the periodScores object
      if (periodScores[period]) {
        // Add the score to the existing period
        periodScores[period].scoreSum += score;
        periodScores[period].scoreCount += 1;
      } else {
        // Create a new entry for the period
        periodScores[period] = {
          scoreSum: score,
          scoreCount: 1,
        };
      }
    }
  });

  // Calculate the average scores for each period
  const averageScores = Object.entries(periodScores).map(
    ([period, scores]) => ({
      averageScore: (scores.scoreSum / scores.scoreCount).toFixed(2),
      period,
    })
  );

  return averageScores;
}
