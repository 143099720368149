import { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

const useSurveyAnswers = (token, question, startDate, endDate) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://mydynasty.ca/ext/api/v1/surveys/answers/`,
        {
          params: {
            score: true,
            session_type: '',
            session: '',
            question,
            start_date: startDate,
            end_date: endDate,
            page: 1,
            page_size: 1000,
          },
          headers: {
            Authorization: token,
          },
        }
      );

      // Sort the result array in ascending order based on the date
      response.data.results.sort(
        (a, b) => new Date(a.period) - new Date(b.period)
      );

      setData(response.data);
    } catch (error) {
      console.error('Error fetching survey answers:', error);
    }
  };

  useQuery(['surveyAnswers', question, startDate, endDate], fetchData, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return data.results;
};

export default useSurveyAnswers;
