import './App.css';
import theme from './components/Theme';
import { ThemeProvider } from '@mui/material';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import AdminLoginScreen from './screens/AdminLoginScreen';
import setAdminAuthToken from './utils/setAdminAuthToken';

import { QueryClient, QueryClientProvider } from 'react-query';
import AdminHeader from './components/AdminHeader';
import isTokenValid from './utils/isTokenValid';
import { logout } from './actions/adminUserActions';
import ResultScreen from './screens/ResultScreen';

// if (localStorage.adminUserInfo) {
//   setAdminAuthToken(JSON.parse(localStorage.adminUserInfo).token);
// }

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { loading: loadingUserAdminInfo, adminUserInfo } = adminUserLogin;

  useEffect(() => {
    if (localStorage.adminUserInfo) {
      const isAdminTokenValid = isTokenValid(
        JSON.parse(localStorage.adminUserInfo).token
      );
      if (!isAdminTokenValid) {
        dispatch(logout());
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          {adminUserInfo && <AdminHeader theme={theme} />}
          <Routes>
            {!adminUserInfo && (
              <>
                <Route
                  path="/admin-login"
                  element={<AdminLoginScreen />}
                ></Route>
              </>
            )}
            {adminUserInfo && (
              <>
                <Route path="/" element={<ResultScreen />}></Route>
              </>
            )}
            <Route
              path="*"
              element={<Navigate to={adminUserInfo ? '/' : '/admin-login'} />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
