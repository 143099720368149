import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { useQuery } from 'react-query';
import EnhancedTable from './EnhancedTable';
import { format } from 'date-fns';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import SaveIcon from '@mui/icons-material/Save';

export function AllSessionsTable() {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [surveyStatus, setSurveyStatus] = useState('1');
  const [orderBy, setOrderBy] = useState('-start_time'); // Default aggregate value is 'day', other options: week, month
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(formatDate(selectedStartDate));
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEndDate(formatDate(selectedEndDate));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const {
    isLoading: isSessionsListLoading,
    error: sessionsListError,
    data: sessionsList,
  } = useQuery(
    [
      'allSessionsList',
      startDate,
      endDate,
      orderBy,
      page,
      pageSize,
      surveyStatus,
    ],
    fetchSurveyAnswers,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  async function fetchSurveyAnswers() {
    const response = await axios.get(
      //   `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&order_by=question&aggregate=${aggregate}&page=1&page_size=1000&start_date=${startDate}&end_date=${endDate}`,
      `https://mydynasty.ca/ext/api/v1/surveys/sessions/?score=true&order_by=${orderBy}&start_date=${startDate}&end_date=${endDate}&survey_status=${surveyStatus}&survey_time&page=${
        page + 1
      }&page_size=${pageSize}`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  console.log(sessionsList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    console.log(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  function flattenObject(obj, parentKey = '') {
    const flattenedObject = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        const value = obj[key];

        if (Array.isArray(value)) {
          if (value.length > 0 && typeof value[0] === 'object') {
            value.forEach((item, index) => {
              const nestedObject = flattenObject(item, `${newKey}.${index}`);
              Object.assign(flattenedObject, nestedObject);
            });
          } else {
            flattenedObject[newKey] = value;
          }
        } else if (typeof value === 'object' && value !== null) {
          const nestedObject = flattenObject(value, newKey);
          Object.assign(flattenedObject, nestedObject);
        } else {
          flattenedObject[newKey] = value;
        }
      }
    }

    return flattenedObject;
  }

  const handleExportCSV = () => {
    // const csvData = Papa.unparse(sessionsList.results, {
    //   columns: columns.map((column) => column.id),
    //   header: true,
    // });

    const flattenedArray = sessionsList.results.map((obj) =>
      flattenObject(obj)
    );
    console.log(flattenedArray);

    const csvData = Papa.unparse(flattenedArray);

    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(blob, 'surveys.csv');
  };

  return (
    <Card variant="outlined" className="all-ques-chart">
      <Typography variant="h2" color="#FFF" gutterBottom>
        All sessions
      </Typography>
      <Divider color="#FFF" sx={{ borderBottomWidth: 5 }} />
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ marginRight: '10px' }}
          />
        </div>
        <div>
          <TextField
            id="endDate"
            label="End Date"
            type="date"
            variant="outlined"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ marginRight: '10px' }}
          />
        </div>

        <div>
          <FormControl size="small" style={{ marginRight: '10px' }}>
            <InputLabel id="orderby-select-label">Order By</InputLabel>
            <Select
              labelId="orderby-select-label"
              id="orderby-select-label"
              value={orderBy}
              label="Order By"
              onChange={handleOrderByChange}
              size="small"
              style={{ width: '150px' }}
            >
              <MenuItem value="start_time">
                Start Date &nbsp; <ArrowUpwardIcon fontSize="12" />
              </MenuItem>
              <MenuItem value="-start_time">
                Start Date &nbsp; <ArrowDownwardIcon fontSize="12" />
              </MenuItem>
              <MenuItem value="score">
                Score &nbsp; <ArrowUpwardIcon fontSize="12" />
              </MenuItem>
              <MenuItem value="-score">
                Score &nbsp; <ArrowDownwardIcon fontSize="12" />
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl size="small" style={{ marginRight: '10px' }}>
            <InputLabel id="survey-status-select-label">Sessions</InputLabel>
            <Select
              labelId="survey-status-select-label"
              id="survey-status-select-label"
              value={surveyStatus}
              label="Sessions"
              onChange={(e) => setSurveyStatus(e.target.value)}
              size="small"
              style={{ width: '150px' }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="1">Surveyed</MenuItem>
              <MenuItem value="0">Not Surveyed</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
            onClick={handleExportCSV}
            variant="contained"
            color="white"
            disableElevation
          >
            Export &nbsp; <SaveIcon />
          </Button>
        </div>
      </div>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right">Start</TableCell>
              <TableCell align="right">End</TableCell>
              <TableCell align="right">Session</TableCell>
              <TableCell align="left">Masseuses</TableCell>
              <TableCell align="right">Locker</TableCell>
              <TableCell align="right">Survey Date</TableCell>
              <TableCell align="right">Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isSessionsListLoading &&
              sessionsList.results.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    background: row.session_type?.color,
                  }}
                >
                  <TableCell align="left">
                    {row?.member === null ? row?.customer : row?.member?.name}
                  </TableCell>
                  <TableCell align="left">
                    {format(new Date(row.start_time), 'PP')}
                  </TableCell>
                  <TableCell align="right">
                    {format(new Date(row.start_time), 'p')}
                  </TableCell>
                  <TableCell align="right">
                    {format(new Date(row.end_time), 'p')}
                  </TableCell>
                  <TableCell align="right">{row.session_type?.name}</TableCell>
                  <TableCell align="left">
                    {row.masseuses?.map((m) => `${m.name} `)}
                  </TableCell>
                  <TableCell align="right">{row.locker}</TableCell>
                  <TableCell align="right">
                    {format(new Date(row.survey_time), 'PPp')}
                  </TableCell>
                  <TableCell align="right">
                    {row.score === null ? 'Null' : row.score?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={sessionsList?.count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
