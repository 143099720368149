import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { calculateAverageScores } from '../utils/calculateAverageScores';
import useSurveyAnswers from '../hooks/useSurveyAnswers';
import { useSelector } from 'react-redux';
import useSurveyQuestionDetails from '../hooks/useSurveyQuestionDetails';
import { Card, Divider, TextField, Typography } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function OverallChart({ data }) {
  const averageScores = calculateAverageScores(data);

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(formatDate(selectedStartDate));
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEndDate(formatDate(selectedEndDate));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const question1Details = useSurveyQuestionDetails(adminUserInfo.token, 1);
  const question2Details = useSurveyQuestionDetails(adminUserInfo.token, 2);
  const question3Details = useSurveyQuestionDetails(adminUserInfo.token, 3);
  const question4Details = useSurveyQuestionDetails(adminUserInfo.token, 4);
  const question5Details = useSurveyQuestionDetails(adminUserInfo.token, 5);
  const question6Details = useSurveyQuestionDetails(adminUserInfo.token, 6);
  const question7Details = useSurveyQuestionDetails(adminUserInfo.token, 7);
  const question8Details = useSurveyQuestionDetails(adminUserInfo.token, 8);
  const question9Details = useSurveyQuestionDetails(adminUserInfo.token, 9);

  const question1 = useSurveyAnswers(
    adminUserInfo.token,
    1,
    startDate,
    endDate
  );
  const question2 = useSurveyAnswers(
    adminUserInfo.token,
    2,
    startDate,
    endDate
  );
  const question3 = useSurveyAnswers(
    adminUserInfo.token,
    3,
    startDate,
    endDate
  );
  const question4 = useSurveyAnswers(
    adminUserInfo.token,
    4,
    startDate,
    endDate
  );
  const question5 = useSurveyAnswers(
    adminUserInfo.token,
    5,
    startDate,
    endDate
  );
  const question6 = useSurveyAnswers(
    adminUserInfo.token,
    6,
    startDate,
    endDate
  );
  const question7 = useSurveyAnswers(
    adminUserInfo.token,
    7,
    startDate,
    endDate
  );
  const question8 = useSurveyAnswers(
    adminUserInfo.token,
    8,
    startDate,
    endDate
  );
  const question9 = useSurveyAnswers(
    adminUserInfo.token,
    9,
    startDate,
    endDate
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Average Score by Day/Week/Month',
      },
    },
  };
  const data2 = {
    labels: question1?.map((q) => q.period.split('T')[0]),
    datasets: [
      {
        label: question1Details?.statement,
        data: question1?.map((q) => q.score),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: question2Details?.statement,
        data: question2?.map((q) => q.score),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgb(53, 162, 235, 0.5)',
      },
      {
        label: question3Details?.statement,
        data: question3?.map((q) => q.score),
        borderColor: 'rgb(248, 111, 3)',
        backgroundColor: 'rgb(248, 111, 3, 0.5)',
      },
      {
        label: question4Details?.statement,
        data: question4?.map((q) => q.score),
        borderColor: 'rgb(101, 84, 175)',
        backgroundColor: 'rgb(101, 84, 175, 0.5)',
      },
      {
        label: question5Details?.statement,
        data: question5?.map((q) => q.score),
        borderColor: 'rgb(142, 172, 80)',
        backgroundColor: 'rgb(142, 172, 80, 0.5)',
      },
      {
        label: question6Details?.statement,
        data: question6?.map((q) => q.score),
        borderColor: 'rgb(179, 19, 18)',
        backgroundColor: 'rgb(179, 19, 18, 0.5)',
      },
      {
        label: question7Details?.statement,
        data: question7?.map((q) => q.score),
        borderColor: 'rgb(246, 250, 112)',
        backgroundColor: 'rgb(246, 250, 112, 0.5)',
      },
      {
        label: question8Details?.statement,
        data: question8?.map((q) => q.score),
        borderColor: 'rgb(0,0,0)',
        backgroundColor: 'rgb(0,0,0, 0.5)',
      },
      {
        label: question9Details?.statement,
        data: question9?.map((q) => q.score),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgb(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <Card variant="outlined" className="all-ques-chart">
      <Typography variant="h2" color="#FFF" gutterBottom>
        Question wise score
      </Typography>
      <Divider color="#FFF" sx={{ borderBottomWidth: 5 }} />
      <br />
      <div className="all-ques-chart-container">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              variant="outlined"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              style={{ marginRight: '10px' }}
            />
          </div>
          <div>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              variant="outlined"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </div>
        </div>
        <Line options={options} data={data2} />
      </div>
    </Card>
  );
}
