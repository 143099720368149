export function calculateAllAverageScores(data) {
  //   // Create an object to store the sum and count for each period
  //   const averageScores = {};

  //   if (!array) return [];

  //   // Iterate over the data
  //   for (const item of array) {
  //     const { period, score } = item;

  //     // Check if the period already exists in the object
  //     if (period in averageScores) {
  //       // Add the score to the sum and increment the count
  //       averageScores[period].sum += score;
  //       averageScores[period].count++;
  //     } else {
  //       // Initialize the period with the first score
  //       averageScores[period] = {
  //         sum: score,
  //         count: 1,
  //       };
  //     }
  //   }

  //   // Calculate the average for each period
  //   for (const period in averageScores) {
  //     const { sum, count } = averageScores[period];
  //     averageScores[period].average = (sum / count).toFixed(2);
  //   }
  //   // Sort the result array in ascending order based on the date
  //   //   averageScores.sort((a, b) => new Date(a.date) - new Date(b.date));

  //   return averageScores;

  // Create an object to store the scores for each period
  const periodScores = {};

  // Iterate over the data array
  data?.forEach((item) => {
    const { question, score, period } = item;

    // Check if the period already exists in the periodScores object
    if (periodScores[period]) {
      // Add the score to the existing period
      periodScores[period].scoreSum += score;
      periodScores[period].scoreCount += 1;
    } else {
      // Create a new entry for the period
      periodScores[period] = {
        scoreSum: score,
        scoreCount: 1,
      };
    }
  });

  // Calculate the average scores for each period
  const averageScores = Object.entries(periodScores).map(
    ([period, scores]) => ({
      averageScore: (scores.scoreSum / scores.scoreCount).toFixed(2),
      period,
    })
  );

  return averageScores;
}
