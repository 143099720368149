import { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { calculateAllAverageScores } from '../utils/calculateAllAverageScores';

const useAllSurveyAnswers = (token, startDate, endDate, aggregate) => {
  const [data, setData] = useState([]);

  console.log(aggregate);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&order_by=question&aggregate=${aggregate}&page=1&page_size=1000&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setData(response.data);
    } catch (error) {
      console.error('Error fetching survey answers:', error);
    }
  };

  useQuery(
    ['allSurveyQuestionAnswers', startDate, endDate, aggregate],
    fetchData,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return calculateAllAverageScores(data.results);
};

export default useAllSurveyAnswers;
