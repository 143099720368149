import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { MasseuseChart } from './MasseuseChart';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import MasseuseSearchableDropdown from './dropdowns/MasseuseSearchableDropdown';

const MasseuseCard = () => {
  const [orderBy, setOrderBy] = useState('-score'); // Default order_by value is '-score'
  const [masseuse, setMasseuse] = useState({
    id: 371,
    name: 'Laura',
    services: 'Men ♂️',
    score: null,
  }); // Default masseuse value is 'Laura'
  const [masseuseId, setMasseuseId] = useState(371); // Default masseuseId value is Laura's id 371
  const [aggregate, setAggregate] = useState('day'); // Default aggregate value is 'day', other options: week, month

  const {
    isLoading: isQuestionsLoading,
    error: questionsError,
    data: questions,
  } = useQuery('surveyQuestions', fetchSurveyQuestions, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  async function fetchSurveyQuestions() {
    const response = await axios.get(
      'https://mydynasty.ca/api/v1/survey/questions/',
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  const {
    isLoading: isAnswersLoading,
    error: answersError,
    data: answers,
  } = useQuery(['surveyAnswersAll', masseuseId], fetchSurveyAnswers, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  async function fetchSurveyAnswers() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&question=&order_by=question&aggregate=all&page=1&page_size=1000&masseuse=${masseuseId}`,
      //   `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&session=&masseuse=${masseuseId}&question=&order_by=&aggregate=all&start_date=&end_date=&page=1&page_size=10000`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    // console.log(response.data);
    return response.data.results;
  }

  const {
    isLoading: isMasseuseChartDataLoading,
    error: masseuseChartDataError,
    data: masseuseChartData,
  } = useQuery(
    ['masseuseChartData', aggregate, masseuseId],
    fetchMasseuseChartData,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  async function fetchMasseuseChartData() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&question=&order_by=question&aggregate=${aggregate}&page=1&page_size=1000&masseuse=${masseuseId}`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    // console.log(response.data);
    return response.data.results;
  }

  const {
    isLoading: isMasseuseListLoading,
    error: masseuseListError,
    data: masseuseList,
  } = useQuery(['masseuseList'], fetchMasseuseList, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  async function fetchMasseuseList() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/masseuses/?order_by=-score&start_date&end_date&score=true&page=1&page_size=100`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    // console.log(response.data);
    return response.data.results;
  }

  const {
    isLoading: isMasseuseDetailsLoading,
    error: masseuseDetailsError,
    data: masseuseDetails,
  } = useQuery(['masseuseDetails', masseuseId], fetchMasseuseDetails, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  async function fetchMasseuseDetails() {
    const response = await axios.get(
      `https://mydynasty.ca/cd/api/v1/masseuses/${masseuseId}`
    );
    console.log(response.data);
    return response.data;
  }

  const combinedData =
    questions && answers
      ? answers.map((answer) => ({
          ...answer,
          statement:
            questions.find((q) => q.id === answer.question)?.statement || 0,
        }))
      : [];

  const masseuseRelatedQuestions = combinedData.filter((obj) =>
    [2, 4, 7].includes(obj.question)
  );

  const handleAggregateChange = (event) => {
    setAggregate(event.target.value);
  };

  function getObjectByQuestion(questionNumber, dataArray) {
    return dataArray.find((obj) => obj.question === questionNumber);
  }

  return (
    <Card className="masseuse-card">
      <CardContent>
        {!isMasseuseListLoading && masseuseList && (
          <MasseuseSearchableDropdown
            masseuseList={masseuseList}
            masseuseId={masseuseId}
            setMasseuseId={setMasseuseId}
            masseuse={masseuse}
            setMasseuse={setMasseuse}
          />
        )}

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={4}>
            {isMasseuseDetailsLoading ? (
              <p>Loading Masseuse Details</p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={masseuseDetails?.image}
                  alt={masseuseDetails?.name}
                  sx={{ width: 150, height: 150 }}
                />
              </div>
            )}
          </Grid>
          {/* Masseuse Overall Average Score Section */}
          <Grid item xs={12} sm={8} style={{ borderLeft: '1px solid white' }}>
            {isMasseuseDetailsLoading ? (
              <p>Loading Masseuse Details</p>
            ) : (
              <div>
                <p className="masseuse-name">{masseuseDetails?.name}</p>
                {isAnswersLoading ? (
                  <p>Loading Masseuse Details</p>
                ) : answers && answers.length > 0 ? (
                  <div>
                    <p className="masseuse-overall-score">
                      {(
                        (getObjectByQuestion(2, combinedData)?.score +
                          getObjectByQuestion(3, combinedData)?.score +
                          getObjectByQuestion(7, combinedData)?.score) /
                        3
                      ).toFixed(2)}
                      <span>/10</span>
                    </p>
                  </div>
                ) : (
                  <Chip
                    label="DATA UNAVAILABLE"
                    color="warning"
                    icon={<ReportProblemIcon />}
                    size="small"
                  />
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {isAnswersLoading ? (
              <p>Loading Masseuse Details</p>
            ) : (
              <div>
                <div className="score-container">
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      fontSize: '18px',
                      lineHeight: '25px',
                    }}
                  >
                    Massage:{' '}
                    {answers && answers.length > 0 ? (
                      getObjectByQuestion(2, combinedData)?.score.toFixed(2)
                    ) : (
                      <Chip
                        label="DATA UNAVAILABLE"
                        color="warning"
                        icon={<ReportProblemIcon />}
                        size="small"
                      />
                    )}
                  </div>
                </div>
                <div className="score-container">
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      fontSize: '18px',
                      lineHeight: '25px',
                    }}
                  >
                    Looks and Sensuality:{' '}
                    {answers && answers.length > 0 ? (
                      getObjectByQuestion(3, combinedData)?.score.toFixed(2)
                    ) : (
                      <Chip
                        label="DATA UNAVAILABLE"
                        color="warning"
                        icon={<ReportProblemIcon />}
                        size="small"
                      />
                    )}
                  </div>
                </div>
                <div className="score-container">
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      fontSize: '18px',
                      lineHeight: '25px',
                    }}
                  >
                    Repeat:{' '}
                    {answers && answers.length > 0 ? (
                      getObjectByQuestion(7, combinedData)?.score.toFixed(2)
                    ) : (
                      <Chip
                        label="DATA UNAVAILABLE"
                        color="warning"
                        icon={<ReportProblemIcon />}
                        size="small"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <FormControl style={{ width: '20%', marginBottom: '10px' }}>
                  <InputLabel id="aggregate-select-label">
                    Aggregation
                  </InputLabel>
                  <Select
                    labelId="aggregate-select-label"
                    id="aggregate-select-label"
                    value={aggregate}
                    label="Aggregation"
                    onChange={handleAggregateChange}
                    size="small"
                  >
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                  </Select>
                </FormControl>{' '}
                <br />
                {!isMasseuseChartDataLoading &&
                masseuseChartData &&
                masseuseChartData.length > 0 ? (
                  <MasseuseChart data={masseuseChartData} />
                ) : (
                  <Chip
                    label="DATA UNAVAILABLE"
                    color="warning"
                    icon={<ReportProblemIcon />}
                    size="small"
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>{' '}
    </Card>
  );
};

export default MasseuseCard;
