import { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

const useSurveyQuestionDetails = (token, question) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://mydynasty.ca/ext/api/v1/surveys/questions/${question}/`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setData(response.data);
    } catch (error) {
      console.error('Error fetching survey answers:', error);
    }
  };

  useQuery(['surveyQuestion', question], fetchData, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return data;
};

export default useSurveyQuestionDetails;
