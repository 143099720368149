import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import cd_logo from '../img/cd-logo-130.png';
import { Link } from 'react-router-dom';
import { logout } from '../actions/adminUserActions';
import { format } from 'date-fns';

export default function AdminHeader() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to={'/'} style={{ color: '#FFF' }}>
              Results
            </Link>
            {/* &nbsp;
            <Link to={'/result'} style={{ color: '#FFF' }}>
              Result
            </Link> */}
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to={'/'}>
              <img src={cd_logo} alt="" style={{ margin: '10px' }} />{' '}
            </Link>
          </Typography>
          <p style={{ fontSize: '0.9rem', marginRight: '10px' }}>
            {format(new Date(), 'PPp').toUpperCase()}
          </p>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={(e) => handleLogout()}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
