import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const MasseuseRank = () => {
  const [orderBy, setOrderBy] = useState('-score'); // Default order_by value is '-score'

  const queryKey = ['masseuses', orderBy];

  const {
    isLoading: isMasseusesLoading,
    error: masseusesError,
    data: masseuses,
  } = useQuery(queryKey, fetchMasseuses, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  async function fetchMasseuses() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/masseuses/?order_by=${orderBy}&start_date&end_date&score=true&page=1&page_size=100`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  function stripNullAndZeroScores(data) {
    if (!data || !Array.isArray(data.results)) {
      return [];
    }

    return data.results.filter(
      (item) => item.score !== null && item.score !== 0
    );
  }

  const filteredData = stripNullAndZeroScores(masseuses);
  // console.log(filteredData);

  return (
    <TableContainer sx={{ maxHeight: 400 }} style={{ minHeight: '400px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#000000' }}>Masseuse</TableCell>
            <TableCell align="left" sx={{ width: '25%', color: '#000000' }}>
              <Button
                variant="text"
                onClick={(e) =>
                  setOrderBy(orderBy === 'score' ? '-score' : 'score')
                }
                style={{ color: 'inherit' }}
              >
                Score &nbsp;
                {orderBy === 'score' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map(({ id, name, score }) => (
            <TableRow
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ color: '#000000' }}>{name}</TableCell>
              <TableCell align="center" sx={{ width: '25%', color: '#000000' }}>
                {score.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasseuseRank;
