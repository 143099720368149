import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const QuestionRank = () => {
  const [orderBy, setOrderBy] = useState('-score'); // Default order_by value is '-score'

  const {
    isLoading: isQuestionsLoading,
    error: questionsError,
    data: questions,
  } = useQuery('surveyQuestions', fetchSurveyQuestions, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  async function fetchSurveyQuestions() {
    const response = await axios.get(
      'https://mydynasty.ca/api/v1/survey/questions/',
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  const queryKey = ['surveyAnswersAll', orderBy];

  const {
    isLoading: isAnswersLoading,
    error: answersError,
    data: answers,
  } = useQuery(['surveyAnswersAll', orderBy], fetchSurveyAnswers, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  async function fetchSurveyAnswers() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&session=&massuese=&question=&order_by=${orderBy}&aggregate=all&start_date=&end_date=&page=1&page_size=10000`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  }

  const combinedData =
    questions && answers
      ? answers.results.map((answer) => ({
          ...answer,
          statement:
            questions.find((q) => q.id === answer.question)?.statement || 0,
        }))
      : [];

  return (
    <TableContainer sx={{ maxHeight: 400 }} style={{ minHeight: '400px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#000000' }}>Question</TableCell>
            <TableCell sx={{ width: '25%', color: '#000000' }} align="left">
              <Button
                variant="text"
                onClick={(e) =>
                  setOrderBy(orderBy === 'score' ? '-score' : 'score')
                }
                style={{ color: 'inherit' }}
              >
                Score &nbsp;
                {orderBy === 'score' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isAnswersLoading &&
            combinedData.map(({ question, statement, score }) => (
              <TableRow
                key={question}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ color: '#000000' }}>{statement}</TableCell>
                <TableCell
                  sx={{ width: '25%', color: '#000000' }}
                  align="center"
                >
                  {score.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionRank;
