import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const OverallSatisfaction = () => {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const fetchSurveyAnswers = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&session=&massuese=&question=&order_by=&aggregate=all&start_date=&end_date=&page=1&page_size=10000`;
    const headers = {
      Authorization: adminUserInfo.token,
    };

    try {
      const response = await axios.get(endpoint, { headers });
      // console.log(response.data);
      const { data } = response;
      const totalScore = data.results.reduce(
        (sum, result) => sum + result.score,
        0
      );
      const maximumScore = 90; // Assuming the maximum score is 100

      const percentage = Number(((totalScore / maximumScore) * 100).toFixed(2));

      return percentage;
    } catch (error) {
      throw new Error('Failed to fetch survey session');
    }
  };

  const { data, error, isLoading } = useQuery(
    'surveyAnswers',
    fetchSurveyAnswers,
    {
      refetchOnMount: false,
      cacheTime: Infinity,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Card sx={{ minWidth: 275 }} variant="outlined" className="overall-card">
      <CardContent>
        <Typography variant="h2" color="#FFF" gutterBottom>
          Overall satisfaction
        </Typography>
        <Divider color="#FFF" sx={{ borderBottomWidth: 5 }} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px',
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant="determinate"
              value={data}
              size={250}
              style={{ color: '#FFFFFF' }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h1"
                component="div"
                style={{ color: '#FFFFFF' }}
              >
                {data}%
              </Typography>
            </Box>
          </Box>
        </div>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default OverallSatisfaction;
