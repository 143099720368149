import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { calculateAverageScores } from '../utils/calculateAverageScores';
import useSurveyAnswers from '../hooks/useSurveyAnswers';
import { useSelector } from 'react-redux';
import useSurveyQuestionDetails from '../hooks/useSurveyQuestionDetails';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { calculateAllAverageScores } from '../utils/calculateAllAverageScores';
import useAllSurveyAnswers from '../hooks/useAllSurveyAnswers';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { useQuery } from 'react-query';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function OverallAvgChart() {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [aggregate, setAggregate] = useState('day'); // Default aggregate value is 'day', other options: week, month

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(formatDate(selectedStartDate));
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEndDate(formatDate(selectedEndDate));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleAggregateChange = (event) => {
    setAggregate(event.target.value);
  };

  const {
    isLoading: isAllSurveyQuestionAnswers,
    error: allSurveyQuestionAnswersError,
    data: averageScores,
  } = useQuery(
    ['allSurveyQuestionAnswers', startDate, endDate, aggregate],
    fetchSurveyAnswers,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  async function fetchSurveyAnswers() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&order_by=question&aggregate=${aggregate}&page=1&page_size=1000&start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return calculateAllAverageScores(response.data.results);
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Average Score by Day/Week/Month',
      },
    },
  };
  const data2 = {
    labels: averageScores?.map((q) => q.period.split('T')[0]),
    datasets: [
      {
        label: 'Average Scores',
        data: averageScores?.map((q) => q.averageScore),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.5,
      },
    ],
  };

  return (
    <Card variant="outlined" className="all-ques-chart">
      <Typography variant="h2" color="#FFF" gutterBottom>
        Average Score by Day/Week/Month
      </Typography>
      <Divider color="#FFF" sx={{ borderBottomWidth: 5 }} />
      <br />
      <div className="all-ques-chart-container">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              variant="outlined"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              style={{ marginRight: '10px' }}
            />
          </div>
          <div>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              variant="outlined"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              style={{ marginRight: '10px' }}
            />
          </div>

          <div>
            <FormControl size="small">
              <InputLabel id="aggregate-select-label">Aggregation</InputLabel>
              <Select
                labelId="aggregate-select-label"
                id="aggregate-select-label"
                value={aggregate}
                label="Aggregation"
                onChange={handleAggregateChange}
                size="small"
                style={{ width: '150px' }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Line options={options} data={data2} />
      </div>
    </Card>
  );
}
