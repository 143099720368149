import { Autocomplete, LinearProgress, TextField } from '@mui/material';

const MasseuseSearchableDropdown = ({
  masseuseList,
  masseuseId,
  setMasseuseId,
  masseuse,
  setMasseuse,
}) => {
  // Set default props for batches dropdown
  const defaultBatchProps = {
    options: masseuseList ? masseuseList : [],
    getOptionLabel: (masseuse) => masseuse.name,
  };

  return (
    <>
      <Autocomplete
        {...defaultBatchProps}
        value={masseuse}
        disableClearable
        onChange={(event, newValue) => {
          setMasseuseId(newValue.id);
          setMasseuse(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Masseuse"
            variant="outlined"
            size="small"
            style={{ width: '25%' }}
          />
        )}
      />
      {/* {loadingBatches && <LinearProgress />} */}
    </>
  );
};

export default MasseuseSearchableDropdown;
