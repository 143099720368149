import axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { OverallChart } from './OverallChart';

const OverallSurveyData = () => {
  const [masseuseId, setMasseuseId] = useState(371);
  const [aggregate, setAggregate] = useState('day'); // Default aggregate value is 'day', other options: week, month

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const {
    isLoading: isAnswersLoading,
    error: answersError,
    data: answers,
  } = useQuery(['overallSurveyData', aggregate], fetchSurveyAnswers, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  async function fetchSurveyAnswers() {
    const response = await axios.get(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/?score=true&session_type=&session=&question=&aggregate=${aggregate}&start_date=&end_date=&page=1&page_size=1000`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    // console.log(response.data);
    return response.data.results;
  }

  return (
    <div>
      <OverallChart />
    </div>
  );
};

export default OverallSurveyData;
