import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { calculateAverageScores } from '../utils/calculateAverageScores';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function MasseuseChart({ data }) {
  const averageScores = calculateAverageScores(data);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Average Score by Day/Week/Month',
      },
    },
  };
  const data2 = {
    labels: averageScores.map((score) => score.period),
    datasets: [
      {
        label: 'Average Score',
        data: averageScores.map((score) => score.averageScore),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      //   {
      //     label: 'Dataset 2',
      //     data: labels.map(() =>
      //       faker.datatype.number({ min: -1000, max: 1000 })
      //     ),
      //     borderColor: 'rgb(53, 162, 235)',
      //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
      //   },
    ],
  };

  return <Line options={options} data={data2} />;
}
