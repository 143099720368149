export const API_URI = 'https://mydynasty.ca/cd/api/v1';
export const CD_API_URI = 'https://mydynasty.ca/api/v1';
// export const API_URI = 'http://127.0.0.1:8000/cd/api/v1';
export const GA_TRACKING_ID = 'G-BGDLEP2KMB';

export const LOCKER_CHOICES = {
  0: 'None',
  1: '1-5',
  6: '6-19',
  20: '20-27',
  28: '28-38',
};

export const ROBE_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};

export const SHOE_CHOICES = {
  0: 'None',
  1: 'Small (black)',
  2: 'Large (beige)',
};

export const SHORTS_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};
