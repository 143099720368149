import React from 'react';
import OverallSatisfaction from '../components/OverallSatisfaction';
import { Container, Divider, Grid, Typography } from '@mui/material';

import Rankings from '../components/Rankings';
import MasseuseCard from '../components/MasseuseCard';
import OverallSurveyData from '../components/OverallSurveyData';
import { OverallAvgChart } from '../components/OverallAvgChart';
import { AllSessionsTable } from '../components/AllSessionsTable';

const ResultScreen = () => {
  return (
    <Container>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={4}>
          <OverallSatisfaction />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Rankings />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Compare Masseuses
          </Typography>
          <Divider color="#FFF" sx={{ borderBottomWidth: 5 }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MasseuseCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MasseuseCard />
        </Grid>
        <Grid item xs={12}>
          <AllSessionsTable />
        </Grid>
        <Grid item xs={12}>
          <OverallAvgChart />
        </Grid>
        {/* <Grid item xs={12}>
          <Sessions />
        </Grid> */}
        <Grid item xs={12}>
          <OverallSurveyData />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResultScreen;
