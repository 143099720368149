import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import alertReducer from './reducers/alertReducers';
import { adminUserLoginReducer } from './reducers/adminUserReducers';

const reducer = combineReducers({
  adminUserLogin: adminUserLoginReducer,
  alert: alertReducer,
});

const adminUserInfoFromStorage = localStorage.getItem('adminUserInfo')
  ? JSON.parse(localStorage.getItem('adminUserInfo'))
  : null;

const initialState = {
  adminUserLogin: { adminUserInfo: adminUserInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
