import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const MassageRank = () => {
  const {
    isLoading: isSessionTypesLoading,
    error: sessionTypesError,
    data: sessionTypes,
  } = useQuery('sessionTypes', fetchMassageTypes, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  async function fetchMassageTypes() {
    const response = await axios.get(
      'https://mydynasty.ca/ext/api/v1/surveys/session_types/?score=true&order_by=-score&start_date&end_date',
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  function stripNullAndZeroScores(data) {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.filter((item) => item.score !== null && item.score !== 0);
  }

  const filteredData = stripNullAndZeroScores(sessionTypes);
  console.log(filteredData);

  return (
    <TableContainer sx={{ maxHeight: 400 }} style={{ minHeight: '400px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#000000' }}>Name</TableCell>
            <TableCell sx={{ color: '#000000' }}>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map(({ id, name, score }) => (
            <TableRow
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ color: '#000000' }}>{name}</TableCell>
              <TableCell sx={{ color: '#000000' }}>
                {score.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MassageRank;
